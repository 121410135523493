import React, { useState } from "react"

type PageManagerProps = {
  children: React.ReactNode
}

type PageManagerState = {
  pageLoaded: boolean
  setPageLoaded: (loaded: boolean) => void
}

const defaultPage = {
  pageLoaded: false,
  setPageLoaded: () => {
    throw new Error("Context not initialized.")
  },
}

export const PageContext = React.createContext<PageManagerState>(defaultPage)

const PageManager: React.FC<PageManagerProps> = ({ children }) => {
  const [pageLoadedState, setPageLoadedState] = useState(false)

  const setPageLoaded = (loaded: boolean) => {
    setPageLoadedState(loaded)
  }

  return (
    <PageContext.Provider
      value={{
        pageLoaded: pageLoadedState,
        setPageLoaded: setPageLoaded,
      }}
    >
      {children}
    </PageContext.Provider>
  )
}

export default ({ element }) => <PageManager>{element}</PageManager>
