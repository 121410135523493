// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-brink-tsx": () => import("./../src/pages/brink.tsx" /* webpackChunkName: "component---src-pages-brink-tsx" */),
  "component---src-pages-cad-tsx": () => import("./../src/pages/cad.tsx" /* webpackChunkName: "component---src-pages-cad-tsx" */),
  "component---src-pages-checklist-tsx": () => import("./../src/pages/checklist.tsx" /* webpackChunkName: "component---src-pages-checklist-tsx" */),
  "component---src-pages-cloud-tsx": () => import("./../src/pages/cloud.tsx" /* webpackChunkName: "component---src-pages-cloud-tsx" */),
  "component---src-pages-custom-tsx": () => import("./../src/pages/custom.tsx" /* webpackChunkName: "component---src-pages-custom-tsx" */),
  "component---src-pages-erp-tsx": () => import("./../src/pages/erp.tsx" /* webpackChunkName: "component---src-pages-erp-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ned-air-tsx": () => import("./../src/pages/ned-air.tsx" /* webpackChunkName: "component---src-pages-ned-air-tsx" */),
  "component---src-pages-power-bi-tsx": () => import("./../src/pages/power-bi.tsx" /* webpackChunkName: "component---src-pages-power-bi-tsx" */),
  "component---src-pages-technische-software-tsx": () => import("./../src/pages/technische-software.tsx" /* webpackChunkName: "component---src-pages-technische-software-tsx" */),
  "component---src-pages-time-management-tsx": () => import("./../src/pages/time-management.tsx" /* webpackChunkName: "component---src-pages-time-management-tsx" */),
  "component---src-pages-websites-and-apps-tsx": () => import("./../src/pages/websites-and-apps.tsx" /* webpackChunkName: "component---src-pages-websites-and-apps-tsx" */)
}

